<!-- Container-fluid starts -->
<div class="container-fluid">

    <div class="col-sm-12 ">
        <div class="card">
            <div class="card-header">
                <h5>Proceo de Firma para conformidad de calidad
                    <button type="button" class="btn-close close pull-right" aria-label="Close"
                        (click)="bsModalRef.hide()">
                        <span aria-hidden="true" class="visually-hidden">&times;</span>
                    </button>
                </h5>
            </div>

            <div class="page-break">
                <div class="row">
                    <div class="col-sm-12 col-xl-12">
                        <div class="card-body">
                            <div class="row justify-content-center">
                                <div style="margin: auto;" class="firma-digital">
                                    <div class="firma-digital--body">
                                        &nbsp;FIRMA DIGITAL:
                                        <canvas #firmaDigital width="450" height="160"
                                            style="touch-action: none;"></canvas>
                                    </div>
                                </div>
                                <br>
                                <br>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-body">
            <div class="row justify-content-center">
                <div class="btn-group">
                    <button class="btn btn-primary" type="submit" (click)="guardarProceso()">Guardar</button>
                    &nbsp;

                    <button class="btn btn-warning" type="button" (click)="Cancelaringreso()">Cancelar</button>
                </div>


            </div>
        </div>


    </div>

    <div class="spinnerBackdrop" [ngStyle]="{'display': Cargando }">
        <div class="spinnerContent">
            <div class="spinnerItem">
                <div class="line bg-primary"></div>
                <div class="line bg-primary"></div>
                <div class="line bg-primary"></div>
                <div class="line bg-primary"></div>
            </div>
            <div class="MensajeDeEspera">Cargando...!!!</div>
        </div>
    </div>


</div>