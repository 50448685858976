import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import SignaturePad from 'signature_pad';
import Swal from 'sweetalert2';
import { PedidosService } from 'src/app/shared/services/pedidos.service';
import { ListarEmpresasService } from 'src/app/shared/services/listar-empresas.service';


@Component({
  selector: 'app-firmacalidad',
  templateUrl: './firmacalidad.component.html',
  styleUrls: ['./firmacalidad.component.scss']
})
export class FirmacalidadComponent implements OnInit {

  @ViewChild('firmaDigital', { static: true }) signaturePadElement: any;

  Cargando: string;
  signaturePad: any;
  datos_get: any;
  IdEmpresa: string;
  UrlFirma: string

  constructor(public bsModalRef: BsModalRef, public options: ModalOptions,
    public pedidosServices: PedidosService,
    private listarempresasservice: ListarEmpresasService,
  ) {

    this.IdEmpresa = JSON.parse(sessionStorage.getItem('IdEmpresa'));
    this.datos_get = this.options.initialState;

    this.Cargando = 'None';
  }

  ngAfterViewInit(): void {
    this.signaturePad = new SignaturePad(this.signaturePadElement.nativeElement, {
      backgroundColor: "rgb(255, 255, 255)"
    });
  }

  ngOnInit(): void {
  }

  Cancelaringreso() {
    this.bsModalRef.hide();
  }

  URLtoBlob(dataURL: any) {
    const partes = dataURL.split(';base64,');
    const contentType = partes[0].split(':')[1];
    const raw = window.atob(partes[1]);
    const rawL = raw.length;
    const array = new Uint8Array(rawL);
    for (let i = 0; i < rawL; i++) {
      array[i] = raw.charCodeAt(i);
    }

    return new Blob([array], { type: contentType })
  }

  async guardarProceso() {
    try {
      if (this.signaturePad.isEmpty()) {
        Swal.fire({
          title: "La firma del documento es obligatorio segun Res. 214 de 15-02-2022 del MinSalud.",
          icon: 'warning'
        });
      }
      else {

        this.Cargando = 'block';

        const blob = this.URLtoBlob(this.signaturePad.toDataURL('image/jpeg', 0.5));
        const bodyfirma = new FormData();
        bodyfirma.append('imagen', blob);
        bodyfirma.append('NameBucket', 'adentlab/' + this.IdEmpresa + '/Firmas');
        bodyfirma.append('extArchivo', 'jpg');
        bodyfirma.append('typeArchivo', 'image/jpeg');

        await this.listarempresasservice.Cargar_Firma_Calidad_S3(bodyfirma).then(async res => {
          this.UrlFirma = res.Cargar_Firma_Calidad_S3
        })
        
        await this.pedidosServices.UpdateFirmaCalidad({
          UrlFirma: this.UrlFirma,
          Dtll: this.datos_get
        }).then(async resp => {
           if (this.datos_get.length != 0) {
            let resp: any
            for (let i in this.datos_get) {
              await this.pedidosServices.UpdateDllRealiza({
                iDDtllhistorico: this.datos_get[i].idtblDtllHistorico
              }).then(async (res) => {
                if (res) {
                  resp = 1
                } else {
                  resp = 0
                }
              })
            }
            if (resp == 1) {
              this.Cargando = 'none';
              Swal.fire({
                title: "Proceso completado correctamente",
                icon: 'success'
              });

              this.bsModalRef.hide()

            } else {
              this.Cargando = 'none';
              Swal.fire({
                title: "No ha realizado ninguna accion..!!",
                text: "¡Por favor comunicarce con el administrador del sistema!",
                icon: 'warning'
              });

              this.bsModalRef.hide()

            }
          }

        })
      }

    } catch (error) {
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }




  }


}
