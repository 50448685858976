import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import SignaturePad from 'signature_pad';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ListarEmpresasService } from 'src/app/shared/services/listar-empresas.service';
import { ListaEmpresaPreOrdenRoot, ListarEmpresas, ListaTrabajosPreOrdenRoot, ListaTrabajosPreOrden, BusTerceroIDRoot, BusTerceroID } from '../../../shared/models/listarEmpresas';
import { formatDate } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import * as svg from "save-svg-as-png";


@Component({
  selector: 'app-orden-trabajo',
  templateUrl: './orden-trabajo.component.html',
  styleUrls: ['./orden-trabajo.component.scss']
})
export class OrdenTrabajoComponent implements OnInit {

  @ViewChild('firmaDigital', { static: true }) signaturePadElement: any;
  @ViewChild('pdfTable', { static: false }) pdfTable: ElementRef;

  signaturePad: any;

  validationForm: FormGroup;
  logo: string;
  nombreEmpresa: string;
  direccion: string
  telefono: string;
  ciudad: string;
  pais: string;
  email: string;
  emailNoti: string;
  Cargando: string;
  svgDienteSel: string;
  svgDienteNoSel: string;
  strDientes: string;
  IdEmpresa: number
  IdTrabajo: number;
  NomTrabajo: string;

  NomArchivoRut: string;
  ArchivoRut: any;
  UrlRut: string;

  NomArchivoFoto: string;
  ArchivoFoto: any;
  UrlFotoPac: string;

  NomArchivoSTL: string;
  ArchivoSTL: any;
  UrlSTL: string;

  UrlFirma: string
  ArchivoPDF: any;
  UrlPDF: string

  base64: any;
  arrTrabajos: ListaTrabajosPreOrden[];
  arrDientes: any[];
  dientesSelecionados: string;

  keywordTrabajo: string;
  logobase64: any;
  pdfOptions: any;
  butonguardar: boolean;

  constructor(private fb: FormBuilder,
    private activateRoute: ActivatedRoute,
    private listarempresasservice: ListarEmpresasService,
    private sanitizer: DomSanitizer,
    private router: Router) {

    this.IdTrabajo = 0

    this.NomArchivoRut = 'Adjunto Rut..!!'
    this.NomArchivoSTL = 'Adjunto el archivo comprimido de los STL..!!'
    this.NomArchivoFoto = 'Adjunto el archivo comprimido de los Fotos del paciente..!!'

    this.svgDienteSel = "#FF0000"
    this.svgDienteNoSel = "#000000"
    this.arrDientes = [];
    this.Cargando = 'none'

    this.keywordTrabajo = 'name'
    this.butonguardar = false;

    const tiempoTranscurrido = Date.now();
    const hoy = new Date(tiempoTranscurrido);

    this.validationForm = this.fb.group({
      IdEmpresa: [''],
      IdSucursal: [''],
      idDoctor: ['', [Validators.required]],
      NomDoctor: ['', [Validators.required]],
      DirDoctor: ['', [Validators.required]],
      TelDoctor: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      CorDoctor: ['', [Validators.required, Validators.email]],
      RegDoctor: ['', [Validators.required]],
      Clinica: [''],
      Sucursal: [''],
      FechaOrden: [formatDate(hoy.toISOString(), "yyyy-MM-dd", "en-CO", "-0500")],
      FechaEntrega: ['', [Validators.required]],
      NumOrden: ['', [Validators.required]],
      IdPaciente: ['', [Validators.required]],
      NomPaciente: ['', [Validators.required]],
      DirPaciente: [''],
      TelPaciente: [''],
      CorPaciente: [''],
      HisClinicaPaciente: ['', [Validators.required]],
      TipoTrabajo: ['', [Validators.required]],
      Indicaciones: ['', [Validators.required]],
      Color: [''],
      Guia: [''],
      Antagonista: [false],
      ResgMordida: [false],
      RegDientes: [''],
      UrlFirmaDoctor: [''],
      UrlRut: [''],
      UrlFotosPaciente: [''],
      UrlSTL: [''],
      IdTerceroDoc: ['0'],
      IdPacienteInt: ['0'],
      IdEstado: [1],
    })
  }

  ngAfterViewInit(): void {
    this.signaturePad = new SignaturePad(this.signaturePadElement.nativeElement, {
      backgroundColor: "rgb(255, 255, 255)"
    });
  }

  async ngOnInit() {
    try {

      this.activateRoute.params.subscribe(async params => {
        this.IdEmpresa = params.IdEmpresa
        this.validationForm.get("IdEmpresa").setValue(this.IdEmpresa)
        this.validationForm.get("IdSucursal").setValue(this.IdEmpresa)

        await this.Cargar_Empresa(params.IdEmpresa)
        await this.Cargar_Trabajos_Empresa(params.IdEmpresa)
        await this.Cargar_Num_Orden(params.IdEmpresa)

      });

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }
  }

  OnClick($event) {
    if ($event.target.attributes[3].textContent == "#000000") {
      $event.target.attributes[3].textContent = this.svgDienteSel
      this.arrDientes.push($event.target.attributes[1].value)
    } else {
      $event.target.attributes[3].textContent = this.svgDienteNoSel
      const filteredLibraries = this.arrDientes.filter((item) => item !== $event.target.attributes[1].value)
      this.arrDientes = filteredLibraries
    }

    this.dientesSelecionados = this.arrDientes.toString()
  }

  limpiarfirma() {
    this.signaturePad.clear();
  }

  toDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  }

  async Cargar_Empresa(idEmpresa) {
    try {
      this.Cargando = 'Block'
      await this.listarempresasservice.getEmpresaNoToken({
        IdEmpresa: idEmpresa
      }).then((res: ListaEmpresaPreOrdenRoot) => {

        this.logo = res.ListaEmpresaPreOrden[0].ChrImagen
        this.nombreEmpresa = res.ListaEmpresaPreOrden[0].ChrNonEmp
        this.direccion = res.ListaEmpresaPreOrden[0].ChrDirEmp
        this.telefono = res.ListaEmpresaPreOrden[0].ChrNuTele
        this.ciudad = res.ListaEmpresaPreOrden[0].Ciudad
        this.pais = res.ListaEmpresaPreOrden[0].Pais
        this.email = res.ListaEmpresaPreOrden[0].ChrEmailOficial
        this.emailNoti = res.ListaEmpresaPreOrden[0].ChrEmailNoti
      })
      this.Cargando = 'none'
    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }

  }

  async Cargar_Trabajos_Empresa(idEmpresa) {
    try {
      this.Cargando = 'Block'
      await this.listarempresasservice.getTrabajosNoToken({
        IdEmpresa: idEmpresa
      }).then((res: ListaTrabajosPreOrdenRoot) => {
        this.arrTrabajos = res.ListaTrabajosPreOrden
      })
      this.Cargando = 'none'
    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }

  }

  async Cargar_Num_Orden(idEmpresa) {
    try {
      this.Cargando = 'Block'
      await this.listarempresasservice.getNumOrdenNoToken({
        IdEmpresa: idEmpresa
      }).then((res: any) => {
        const num = res.NumPreOrden[0].NumOrden + 1
        this.validationForm.get("NumOrden").setValue('D-' + num)
      })
      this.Cargando = 'none'
    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }

  }

  async selectEventTrabajo(item) {
    try {
      this.IdTrabajo = item.id
      this.NomTrabajo = item.name

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }
  }

  onChangeSearch(val: string) {
  }

  onFocusedtrabjo(e) {
    try {
    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }
  }

  captureRut($event): any {
    this.NomArchivoRut = $event.target.files[0].name
    this.ArchivoRut = $event.target.files[0]

    Swal.fire({
      title: 'Estas seguro?',
      html: '<p>Estas seguro que deseas subir a la prescripcion el archivo : <b style="font-weight: bold">' + this.NomArchivoRut + '</b> </p>',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmado'
    }).then(async (result) => {
      if (result.isConfirmed) {

        this.Cargando = 'block';

        if (this.ArchivoRut != undefined) {
          //Rut
          const bodyrut = new FormData();
          bodyrut.append('imagen', this.ArchivoRut);
          bodyrut.append('NameBucket', 'adentlab/' + this.IdEmpresa + '/Pre-Orden/' + this.validationForm.get("NumOrden").value);
          bodyrut.append('extArchivo', this.getFileExtension(this.ArchivoRut.name));
          bodyrut.append('typeArchivo', this.ArchivoRut.type);

          await this.listarempresasservice.Cargar_Archivos_S3(bodyrut).then(async res => {
            this.UrlRut = res.Cargar_Archivos_S3
            this.validationForm.get("UrlRut").setValue(this.UrlRut)
          })
        }
        this.Cargando = 'none';
      } else {
        this.NomArchivoRut = 'Adjunto Rut..!!'
        this.ArchivoRut = undefined
        Swal.fire({
          title: "!Proceso no realizado!",
          html: ' <div class="well well-sm ContenedorGris"><label>Error </label><br /><p> el usuario cancelo la operacion</p></div>', icon: 'error'
        });
      }
    })

  }

  captureFotosPaciente($event): any {
    this.NomArchivoFoto = $event.target.files[0].name
    this.ArchivoFoto = $event.target.files[0]

    Swal.fire({
      title: 'Estas seguro?',
      html: '<p>Estas seguro que deseas subir a la prescripcion el archivo : <b style="font-weight: bold">' + this.NomArchivoFoto + '</b> </p>',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmado'
    }).then(async (result) => {
      if (result.isConfirmed) {

        this.Cargando = 'block';

        if (this.ArchivoFoto != undefined) {
          //Fotos
          const bodyfotos = new FormData();
          bodyfotos.append('imagen', this.ArchivoFoto);
          bodyfotos.append('NameBucket', 'adentlab/' + this.IdEmpresa + '/Pre-Orden/' + this.validationForm.get("NumOrden").value);
          bodyfotos.append('extArchivo', this.getFileExtension(this.ArchivoFoto.name));
          bodyfotos.append('typeArchivo', this.ArchivoFoto.type);

          await this.listarempresasservice.Cargar_Archivos_S3(bodyfotos).then(async res => {
            this.UrlFotoPac = res.Cargar_Archivos_S3
            this.validationForm.get("UrlFotosPaciente").setValue(this.UrlFotoPac)
          })
        }

        this.Cargando = 'none';
      } else {
        this.NomArchivoFoto = 'Adjunto el archivo comprimido de los Fotos del paciente..!!'
        this.ArchivoFoto = undefined
        Swal.fire({
          title: "!Proceso no realizado!",
          html: ' <div class="well well-sm ContenedorGris"><label>Error </label><br /><p> el usuario cancelo la operacion</p></div>', icon: 'error'
        });
      }
    })

  }

  async captureSTL($event): Promise<any> {
    this.NomArchivoSTL = $event.target.files[0].name
    this.ArchivoSTL = $event.target.files[0]

    Swal.fire({
      title: 'Estas seguro?',
      html: '<p>Estas seguro que deseas subir a la prescripcion el archivo : <b style="font-weight: bold">' + this.NomArchivoSTL + '</b> </p>',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmado'
    }).then(async (result) => {
      if (result.isConfirmed) {

        this.Cargando = 'block';

        if (this.ArchivoSTL != undefined) {
          //STL
          const body = new FormData();
          body.append('imagen', this.ArchivoSTL);
          body.append('NameBucket', 'adentlab/' + this.IdEmpresa + '/Pre-Orden/' + this.validationForm.get("NumOrden").value);
          body.append('extArchivo', this.getFileExtension(this.ArchivoSTL.name));
          body.append('typeArchivo', this.ArchivoSTL.type);

          await this.listarempresasservice.Cargar_Archivos_S3(body).then(async res => {
            this.UrlSTL = res.Cargar_Archivos_S3
            this.validationForm.get("UrlSTL").setValue(this.UrlSTL)
          })
          this.Cargando = 'none';
        }
      } else {
        this.NomArchivoSTL = 'Adjunto el archivo comprimido de los STL..!!'
        this.ArchivoSTL = undefined
        Swal.fire({
          title: "!Proceso no realizado!",
          html: ' <div class="well well-sm ContenedorGris"><label>Error </label><br /><p> el usuario cancelo la operacion</p></div>', icon: 'error'
        });
      }
    })
  }

  getFileExtension(filename) {
    return filename.split('.').pop();
  }

  URLtoBlob(dataURL: any) {
    const partes = dataURL.split(';base64,');
    const contentType = partes[0].split(':')[1];
    const raw = window.atob(partes[1]);
    const rawL = raw.length;
    const array = new Uint8Array(rawL);
    for (let i = 0; i < rawL; i++) {
      array[i] = raw.charCodeAt(i);
    }

    return new Blob([array], { type: contentType })
  }

  async GuardarOrden() {
    try {

      if (this.IdTrabajo != 0) {

        if (this.signaturePad.isEmpty()) {
          Swal.fire({
            title: "La firma del documento es obligatorio segun Res. 214 de 15-02-2022 del MinSalud.",
            icon: 'warning'
          });
        }

        else {

          this.Cargando = 'block';

          this.butonguardar = true;

          this.validationForm.get("TipoTrabajo").setValue(this.IdTrabajo)
          this.validationForm.get("RegDientes").setValue(this.arrDientes.toString())

          const blob = this.URLtoBlob(this.signaturePad.toDataURL('image/jpeg', 0.5));
          const bodyfirma = new FormData();
          bodyfirma.append('imagen', blob);
          bodyfirma.append('NameBucket', 'adentlab/' + this.IdEmpresa + '/Pre-Orden/' + this.validationForm.get("NumOrden").value);
          bodyfirma.append('extArchivo', 'jpg');
          bodyfirma.append('typeArchivo', 'image/jpeg');

          await this.listarempresasservice.Cargar_Archivos_S3(bodyfirma).then(async res => {
            this.UrlFirma = res.Cargar_Archivos_S3
            this.validationForm.get("UrlFirmaDoctor").setValue(this.UrlFirma)
          })

          await this.listarempresasservice.InsertarOrdenNoToken(this.validationForm.value).then(async res => {

            await this.listarempresasservice.SendEmailPreOdo({
              to: this.validationForm.get("CorDoctor").value,
              doctor: this.validationForm.get("NomDoctor").value,
              paciente: this.validationForm.get("NomPaciente").value,
              consultorio: this.validationForm.get("Clinica").value + ' Sucursal: ' + this.validationForm.get("Sucursal").value,
              trabajo: this.NomTrabajo,
              indicaciones: this.validationForm.get("Indicaciones").value,
              nomLab: this.nombreEmpresa,
              correolab: this.email,
              numerolab: this.telefono,
              numOrden: this.validationForm.get("NumOrden").value,
              URLPdf: res.InsertarPreOrden
            })

            await this.listarempresasservice.SendEmailPreLab({
              to: this.emailNoti,
              doctor: this.validationForm.get("NomDoctor").value,
              paciente: this.validationForm.get("NomPaciente").value,
              consultorio: this.validationForm.get("Clinica").value + ' Sucursal: ' + this.validationForm.get("Sucursal").value,
              trabajo: this.NomTrabajo,
              indicaciones: this.validationForm.get("Indicaciones").value,
              nomLab: this.nombreEmpresa,
              correoDoc: this.validationForm.get("CorDoctor").value,
              numeroDoc: this.validationForm.get("TelDoctor").value,
              numOrden: this.validationForm.get("NumOrden").value,
              URLRut: this.validationForm.get("UrlRut").value,
              URLFoto: this.validationForm.get("UrlFotosPaciente").value,
              URLstl: this.validationForm.get("UrlSTL").value,
              URLPdf: res.InsertarPreOrden
            })

            this.Cargando = 'none'
            if (res) {
              Swal.fire({
                title: "Proceso completado correctamente",
                icon: 'success'
              }).then((res) => {
                window.location.reload();
              });
            }

          }).catch((error) => {
            this.Cargando = 'none';
            Swal.fire({
              title: "No se realizo el proceso...!!!",
              html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
              icon: 'error'
            });
          })
        }
      }
      else {
        Swal.fire({
          title: "Informacion",
          text: "¡Para realizar esta accíon al menos debes seleccionar un tipo de trabajo de la lista, por favor verificar!",
          icon: 'info'
        });
      }

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }
  }

  async exportToPDF() {
    return new Promise((resolve, reject) => {
      // Extraemos el
      const DATA = document.getElementById('htmlData');
      const doc = new jsPDF('l', 'mm', 'a5', true);
      const options = {
        background: "white",
        scale: 1,
        logging: true,
        letterRendering: true,
        useCORS: true,
        allowTaint: true
      };
      html2canvas(DATA, options).then((canvas) => {

        const img = canvas.toDataURL('"image/jpeg"');

        // Add image Canvas to PDF
        const bufferX = 15;
        const bufferY = 2
        const imgProps = (doc as any).getImageProperties(img);
        const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        doc.addImage(img, 'jpeg', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
        return doc;
      }).then((docResult) => {
        //return docResult.output('blob')
        resolve(new File([docResult.output('blob')], `Orden ${this.validationForm.get("NumOrden").value}.pdf`))
        //docResult.save(`${new Date().toISOString()}_tutorial.pdf`);
      });

    })
  }

  async DatosDoc($event) {
    this.Cargando = 'Block'
    await this.listarempresasservice.BuscasIdTerceroNoToken({
      IdEmpresa: this.IdEmpresa,
      IdSucursal: this.IdEmpresa,
      Identificacion: $event.target.value,
      TipoTercero: 5
    }).then((res: BusTerceroIDRoot) => {
      if (res.BusTerceroID[0] != undefined) {
        this.validationForm.get("NomDoctor").setValue(res.BusTerceroID[0].ChrNombreCompleto)
        this.validationForm.get("DirDoctor").setValue(res.BusTerceroID[0].ChrDirreccion)
        this.validationForm.get("TelDoctor").setValue(res.BusTerceroID[0].ChrTelefono)
        this.validationForm.get("CorDoctor").setValue(res.BusTerceroID[0].ChrEmail)
        this.validationForm.get("IdTerceroDoc").setValue(res.BusTerceroID[0].idTblTerceros)
      }
    })
    this.Cargando = 'none'
  }

  async DatosPaci($event) {
    this.Cargando = 'Block'
    await this.listarempresasservice.BuscasIdTerceroNoToken({
      IdEmpresa: this.IdEmpresa,
      IdSucursal: this.IdEmpresa,
      Identificacion: $event.target.value,
      TipoTercero: 4
    }).then((res: BusTerceroIDRoot) => {
      if (res.BusTerceroID[0] != undefined) {
        this.validationForm.get("NomPaciente").setValue(res.BusTerceroID[0].ChrNombreCompleto)
        this.validationForm.get("DirPaciente").setValue(res.BusTerceroID[0].ChrDirreccion)
        this.validationForm.get("TelPaciente").setValue(res.BusTerceroID[0].ChrTelefono)
        this.validationForm.get("CorPaciente").setValue(res.BusTerceroID[0].ChrEmail)
        this.validationForm.get("IdPacienteInt").setValue(res.BusTerceroID[0].idTblTerceros)
      }
    })
    this.Cargando = 'none'
  }

  async CargarDatosTercero(Identificacion: number, TipoTerceroId: number) {

  }

  /*   createPdf() {
      try {
        const pdfContent: any = {
          content: [
            {
              style: 'tableExample',
              table: {
                widths: [100, 400],
                body: [
                  [{
                    image: '',
                    width: 50
                  }, {
                    text: [
                      { text: this.nombreEmpresa + '\n', style: 'header' },
                      { text: 'Direccion: ', style: 'header' },
                      { text: this.direccion },
                      { text: 'Telefono: ', style: 'header' },
                      { text: this.telefono }
                    ]
                  }]
                ]
              },
              layout: 'noBorders'
            },
          ],
          styles: {
            header: {
              fontSize: 14,
              bold: true,
              alignment: 'center'
            },
            subheader: {
              fontSize: 14,
              bold: true
            },
            quote: {
              italics: true
            },
            small: {
              fontSize: 8
            }
          }
        }
   
        const pdf = pdfMake.createPdf(pdfContent)
        pdf.open()
   
      } catch (error) {
        this.Cargando = 'none';
        Swal.fire({
          title: "No se realizo el proceso...!!!",
          html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
          icon: 'error'
        });
      }
    } */


}
