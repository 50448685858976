<form [formGroup]="FromTrabajos">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12 col-xl-12">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-header">
                            <h5 style="text-align: center;"><b class="titulo">Ingreso de Inventario</b></h5>
                            <button type="button" class="btn-close close pull-right" aria-label="Close"
                                (click)="bsModalRef.hide()">
                                <span aria-hidden="true" class="visually-hidden">&times;</span>
                            </button>
                        </div>

                        <div [formArrayName]="'procesos'">
                            <div class="card-body"
                                *ngFor="let item of FromTrabajos.get('procesos')['controls'];  let i = index;"
                                [formGroupName]="i">
                                <hr width=auto>
                                <div class="row">
                                    <label class="col-form-label pt-0"> <b>Caja:</b>
                                        {{FromTrabajos.get('procesos')['controls'][i].value.Caja}}</label>
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    <label class="col-form-label pt-0"> <b>Odontologo:</b>
                                        {{FromTrabajos.get('procesos')['controls'][i].value.Odontologo}}</label>
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    <label class="col-form-label pt-0"> <b>Paciente:</b>
                                        {{FromTrabajos.get('procesos')['controls'][i].value.Paciente}}</label>
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    <label class="col-form-label pt-0"> <b>Proceso:</b>
                                        {{FromTrabajos.get('procesos')['controls'][i].value.Proceso}}</label>
                                </div>


                                <div class="row">
                                    <div class="col-md-3 col-sm-3">
                                        <label class="col-form-label pt-0">*Producto:</label>
                                        <div class="ng-autocomplete">
                                            <ng-autocomplete [data]="arrProductos" [searchKeyword]="keywordProduc"
                                                placeholder="Seleciona Producto"
                                                (selected)='selectEventProduct(i, $event)'
                                                (inputChanged)='onChangeSearch($event)'
                                                (inputFocused)='onFocused($event)' [itemTemplate]="itemTemplate"
                                                [notFoundTemplate]="notFoundTemplate" formControlName="IdProducto"
                                                required [isLoading]='isloading'>
                                            </ng-autocomplete>

                                            <ng-template #itemTemplate let-item>
                                                <a [innerHTML]="item.ChrNombreProd"></a>
                                            </ng-template>

                                            <ng-template #notFoundTemplate let-notFound>
                                                <div [innerHTML]="notFound"></div>
                                            </ng-template>
                                        </div>
                                    </div>

                                    <div class="col-md-1 col-sm-3">
                                        <label class="col-form-label pt-0">*Cantidad</label>
                                        <input type="text" class="form-control" id="validationCustom03"
                                            formControlName="Cantidad" required>
                                    </div>

                                    <div class="col-md-2 col-sm-3">
                                        <label class="col-form-label pt-0">Lote</label>
                                        <input type="text" class="form-control" id="validationCustom03"
                                            formControlName="Lote" required>
                                    </div>

                                    <div class="col-md-2 col-sm-3">
                                        <label class="col-form-label pt-0">Invima</label>
                                        <input type="text" class="form-control" id="validationCustom03"
                                            formControlName="Invima" required>
                                    </div>

                                    <div class="col-md-3 col-sm-3">
                                        <label class="col-form-label pt-0">Referencia</label>
                                        <input type="text" class="form-control" id="validationCustom03"
                                            formControlName="Referencia" required>
                                    </div>

                                    <div class="col-md-1 col-sm13">
                                        <label class="col-form-label pt-0">Agregar</label>
                                        <button class="btn btn-primary"
                                            (click)="Ingresar_Inventario(i, FromTrabajos.value)"><i
                                                class="fa fa-plus-circle"></i></button>
                                    </div>


                                </div>

                                <div class="row">
                                    <div class="table-responsive">
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th style="width:300px" scope="col">Producto</th>
                                                    <th style="width:100px" scope="col">Cantidad</th>
                                                    <th style="width:100px" scope="col">Lote</th>
                                                    <th style="width:100px" scope="col">Invima</th>
                                                    <th style="width:300px" scope="col">Referencia</th>
                                                    <th style="width:100px" scope="col">Acciones</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    *ngFor="let inventario of FromTrabajos.get('procesos')['controls'][i].value.ArrInventario[0]">
                                                    <td>{{inventario.ChrNombreProd}}</td>
                                                    <td>{{inventario.DblSalidaUni}}</td>
                                                    <td>{{inventario.ChrLote}}</td>
                                                    <td>{{inventario.ChrRegInvima}}</td>
                                                    <td>{{inventario.ChrReferencia}}</td>
                                                    <td>
                                                        <!-- <a class="me-3">
                                                          <img src="assets/images/icons/edit.svg" alt="img" />
                                                        </a> -->
                                                        <a
                                                        *ngIf="inventario.idtblinventarios <= '0'"
                                                          href="javascript:void(0);"  (click)="deletelinea(i, inventario)">
                                                          <img src="assets/images/icons/delete.svg" alt="img" />
                                                        </a>
                                                      </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="card-body">

                            <div class="row">
                                <div class="btn-group">
                                    <button *ngIf="!boton" class="btn btn-primary"
                                        type="submit" (click)="guardarInventario()">Guardar</button>
                                    <button *ngIf="boton" class="btn btn-primary" disabled
                                        type="submit">Guardando...!!!</button>
                                    &nbsp;

                                    <button class="btn btn-warning" type="button"
                                        (click)="Cancelaringreso()">Cancelar</button>
                                </div>

                            </div>
                        </div>

                        <div class="spinnerBackdrop" [ngStyle]="{'display': Cargando }">
                            <div class="spinnerContent">
                                <div class="spinnerItem">
                                    <div class="line bg-primary"></div>
                                    <div class="line bg-primary"></div>
                                    <div class="line bg-primary"></div>
                                    <div class="line bg-primary"></div>
                                </div>
                                <div class="MensajeDeEspera">Cargando...!!!</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>