import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FileUploader, FileUploaderOptions, ParsedResponseHeaders } from 'ng2-file-upload';
import { GetReceivedDocumentRoot, GetReceivedDocument, arryIngresoDoc } from 'src/app/shared/models/invoice';
import { InvoiceService } from '../../shared/services/invoice.service'
import { ApidianService } from '../../shared/services/apidian.service'
import Swal from 'sweetalert2';
import { DatatableComponent } from '@swimlane/ngx-datatable';
//import { Cloudinary } from '@cloudinary/angular-5.x';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import xml2js from 'xml2js';
//import * as xml2js from 'xml2js';
//import * as fs from 'file-system';
import 'rxjs/Rx'
import { formatDate } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';


//const URL = 'https://api.cloudinary.com/v1_1/adentlab/raw/upload';

@Component({
  selector: 'app-recibodocumentos',
  templateUrl: './recibodocumentos.component.html',
  styleUrls: ['./recibodocumentos.component.scss'],
  encapsulation: ViewEncapsulation.None
})


export class RecibodocumentosComponent implements OnInit {

  NitEmpresa: string;
  IdEmpresa: string;
  IdSucursal: string;
  Cargando: string;
  sendtobase64: Blob;
  namexml: string;
  urlxml: string
  arrInserDocu: arryIngresoDoc[];
  btnguardar: boolean
  formDocu: FormGroup;

  arrrecive_documents: GetReceivedDocument[];
  temp: GetReceivedDocument[];

  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  public xmlItems: any;
  public uploader: FileUploader;

  constructor(private inoviceService: InvoiceService,
    private _http: HttpClient,
    private invoiceService: InvoiceService,
    private fb: FormBuilder,
    private apidianService: ApidianService) {

    this.IdEmpresa = JSON.parse(sessionStorage.getItem('IdEmpresa'));
    this.IdSucursal = JSON.parse(sessionStorage.getItem('IdSucursal'));
    this.NitEmpresa = JSON.parse(sessionStorage.getItem('NitEmpresa'));

    this.btnguardar = true;
    this.arrInserDocu = [];
    this.sendtobase64;

    this.Cargando = 'none';

    this.formDocu = this.fb.group({
      IdEmpresa: [''],
      IdSucursal: [''],
      Nit: [''],
      name_seller: [''],
      prefijo: [''],
      numero: [''],
      namexml: [''],
      cufe: [''],
      dateissue: [''],
      subtotal: [''],
      iva: [''],
      total: [''],
      totaldescuento: [''],
      acu_recibido: [''],
      rec_bienes: [''],
      aceptacion: [''],
      rechazo: [''],
      realizado: [''],
      docbase64: ['']
    })

  }


  async ngOnInit(): Promise<any> {
    this.cargar_recevied_documents();

    const uploaderOptions: FileUploaderOptions = {
      url: `https://api.cloudinary.com/v1_1/adentlab/raw/upload`,
      autoUpload: false, // Cargar archivos automáticamente al agregarlos a la cola de carga
      isHTML5: true, // Use xhrTransport a favor de iframeTransport
      removeAfterUpload: true, // Calcule el progreso de forma independiente para cada archivo cargado
      queueLimit: 20,
      headers: [ // XHR request headers
        {
          name: 'X-Requested-With',
          value: 'XMLHttpRequest'
        }
      ]
    };

    this.uploader = new FileUploader(uploaderOptions);

    this.uploader.onBuildItemForm = (fileItem: any, form: FormData): any => {
      // Agregue el preajuste de carga sin firmar de Cloudinary al formulario de carga
      form.append('upload_preset', 'adentlab_xml');
      // Usar el valor predeterminado "withCredentials" para las solicitudes CORS
      fileItem.withCredentials = false;
      return { fileItem, form };
    }

    const upsertResponse = async fileItem => {
      // Check if HTTP request was successful
      if (fileItem.status !== 200) {
        console.log('Upload to cloudinary Failed');
        console.log(fileItem);
        return false;
      }
      //console.log(fileItem);
      this.namexml = fileItem.file.name
      this.urlxml = fileItem.data.secure_url
      await this.leer_acrhivo_xml(fileItem)
    }

    // Actualizar el modelo al finalizar la carga de un archivo
    this.uploader.onCompleteItem = (item: any, response: string, status: number, headers: ParsedResponseHeaders) =>
      upsertResponse(
        {
          file: item.file, status,
          data: JSON.parse(response),
        }
      );

  }

  public hasBaseDropZoneOver: boolean = false;
  public hasAnotherDropZoneOver: boolean = false;

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }

  updateFilteseller(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.chrname_seller.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows    
    this.arrrecive_documents = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
    //recarga cuando no tiene nada  

  }

  async cargar_recevied_documents(): Promise<void> {
    try {
      this.Cargando = 'Block';

      const res: GetReceivedDocumentRoot = await this.inoviceService.get_received_documents(
        {
          IdEmpresa: this.IdEmpresa,
          IdSucursal: this.IdSucursal,
        })

      this.temp = [...res.get_received_documents]
      this.arrrecive_documents = res.get_received_documents;

      this.Cargando = 'none';

    } catch (error) {
      Swal.fire({ title: "NO se realizo el proceso comunicate con el administrador", icon: 'error', timer: 3000 });
    }
  }

  async leer_acrhivo_xml(data: any) {
    const parser = new xml2js.Parser();

    var request = new XMLHttpRequest();
    request.withCredentials = false
    request.open('GET', data.data.secure_url, true);
    request.responseType = 'blob';
    request.onload = () => {
      var reader = new FileReader();
      reader.readAsDataURL(request.response);
      reader.onload = (e) => {
        var cadena: any = e.target.result

        //console.log(cadena);
        if (cadena.indexOf("u/") != -1) {
          var comienza = cadena.indexOf("u/")
          cadena = atob(cadena.substring(comienza + 2));
        } else {
          var comienza = cadena.indexOf(",")
          cadena = atob(cadena.substring(comienza + 1));
        }

        parser.parseString(cadena, (err, result) => {
          if (result !== undefined) {
            if (result.AttachedDocument["cac:Attachment"] !== undefined) {
              parser.parseString(result.AttachedDocument["cac:Attachment"][0]["cac:ExternalReference"][0]["cbc:Description"], (err, res) => {
                this.cargar_arreglo(res, data)
              })
            } else {
              Swal.fire({
                title: "NO se realizo el proceso...!!",
                html: ' <div class="well well-sm ContenedorGris"><label>Respuesta DIAN: </label><br /><p>' + 'El archivo ' + this.namexml + ' no es correcto... Por favor comuniquese con su proveedor..!!' + '</p></div>', icon: 'error'
              });

              this.inoviceService.delete_cloudinary({
                'token': data.data.delete_token
              }).then((delet) => {
                console.log(delet);
              })

            }
          } else {
            Swal.fire({
              title: "NO se realizo el proceso...!!",
              html: ' <div class="well well-sm ContenedorGris"><label>Respuesta DIAN: </label><br /><p>' + 'El archivo ' + this.namexml + ' no es correcto... Por favor comuniquese con su proveedor..!!' + '</p></div>', icon: 'error'
            });

            this.inoviceService.delete_cloudinary({
              'token': data.data.delete_token
            }).then((delet) => {
              console.log(delet);
            })
          }


        });
      };
    };
    request.send();

  }

  async leer_acrhivo_xml_envio(ruta): Promise<any> {
    return new Promise(async (resolve, reject) => {

      const parser = new xml2js.Parser();
      var request = new XMLHttpRequest();
      request.open('GET', ruta, true);
      request.responseType = 'blob';
      request.onload = () => {
        var reader = new FileReader();
        reader.readAsDataURL(request.response);
        reader.onload = (e) => {
          var cadena: any = e.target.result
          cadena = (cadena.substring(28));
          resolve(cadena)
        };
      };
      request.send();
    })
  }

  cargar_arreglo(doc: any, data: any) {
    try {
      var totaliva = 0;
      var prefijo = ''

      if (this.NitEmpresa == doc.Invoice["cac:AccountingCustomerParty"][0]["cac:Party"][0]["cac:PartyLegalEntity"][0]["cbc:CompanyID"][0]._) {

        const index = this.arrrecive_documents.findIndex(callback => callback.chrcufe == doc.Invoice["cbc:UUID"][0]._)
        if (index == -1) {

          //Valida Iva
          if (typeof (doc.Invoice["cac:TaxTotal"]) !== 'undefined') {
            totaliva = doc.Invoice["cac:TaxTotal"][0]["cbc:TaxAmount"][0]._;
          }

          //Valida Prefijo
          if (typeof (doc.Invoice["cac:AccountingSupplierParty"][0]["cac:Party"][0]["cac:PartyLegalEntity"][0]["cac:CorporateRegistrationScheme"]) !== 'undefined') {
            prefijo = doc.Invoice["cac:AccountingSupplierParty"][0]["cac:Party"][0]["cac:PartyLegalEntity"][0]["cac:CorporateRegistrationScheme"][0]["cbc:ID"][0];
          }

          //console.log(doc.Invoice["cac:AccountingSupplierParty"][0]["cac:Party"][0]["cac:PartyLegalEntity"][0]);

          /* if (typeof (doc.Invoice["cac:AccountingSupplierParty"][0]["cac:Party"][0]["cac:PartyLegalEntity"][0]["cac:CorporateRegistrationScheme"][0]["cbc:ID"][0]) !== 'undefined'){
            prefijo = doc.Invoice["cac:AccountingSupplierParty"][0]["cac:Party"][0]["cac:PartyLegalEntity"][0]["cac:CorporateRegistrationScheme"][0]["cbc:ID"][0]
          } */

          totaliva = 0;

          const tiempoTranscurrido = Date.now();
          const hoy = new Date(tiempoTranscurrido);
          var hora = doc.Invoice["cbc:IssueTime"][0].substring(0, 8);

          this.formDocu.value.IdEmpresa = this.IdEmpresa,
            this.formDocu.value.IdSucursal = this.IdSucursal,
            this.formDocu.value.Nit = doc.Invoice["cac:AccountingSupplierParty"][0]["cac:Party"][0]["cac:PartyLegalEntity"][0]["cbc:CompanyID"][0]._,
            this.formDocu.value.name_seller = doc.Invoice["cac:AccountingSupplierParty"][0]["cac:Party"][0]["cac:PartyLegalEntity"][0]["cbc:RegistrationName"][0],
            //this.formDocu.value.prefijo = doc.Invoice["cac:AccountingSupplierParty"][0]["cac:Party"][0]["cac:PartyLegalEntity"][0]["cac:CorporateRegistrationScheme"][0]["cbc:ID"][0]
            this.formDocu.value.prefijo = prefijo
          this.formDocu.value.numero = getNumbersInString(doc.Invoice["cbc:ID"][0]),
            this.formDocu.value.namexml = this.namexml,
            this.formDocu.value.cufe = doc.Invoice["cbc:UUID"][0]._,
            this.formDocu.value.dateissue = doc.Invoice["cbc:IssueDate"][0] + "T" + hora,
            this.formDocu.value.subtotal = doc.Invoice["cac:LegalMonetaryTotal"][0]["cbc:LineExtensionAmount"][0]._,
            this.formDocu.value.iva = totaliva,
            this.formDocu.value.total = doc.Invoice["cac:LegalMonetaryTotal"][0]["cbc:PayableAmount"][0]._,
            this.formDocu.value.totaldescuento = 0,
            this.formDocu.value.acu_recibido = 0,
            this.formDocu.value.rec_bienes = 0,
            this.formDocu.value.aceptacion = 0,
            this.formDocu.value.rechazo = 0,
            this.formDocu.value.realizado = formatDate(hoy.toISOString(), "yyyy-MM-ddTHH:mm:ss", "en-CO", "-0500"),
            this.formDocu.value.docbase64 = this.urlxml

          //this.formDocu.value.public_id = data.data.public_id
          //this.formDocu.value.delete_token = data.data.delete_token
          this.arrInserDocu.push(this.formDocu.value);
          this.formDocu.reset();

          this.btnguardar = false;

        } else {
          Swal.fire({ title: "Este Archivo ya fue insertado anteriormente...!!! Por favor verifique", icon: 'info' });

          this.inoviceService.delete_cloudinary({
            'token': data.data.delete_token
          }).then((delet) => {
            console.log(delet);
          })

        }


      } else {

        Swal.fire({
          title: "NO se realizo el proceso comunicate con el administrador",
          html: ' <div class="well well-sm ContenedorGris"><label>Respuesta DIAN: </label><br /><p>' + 'El archivo ' + this.namexml + ' no pertenece al comprador... Por favor verifique..!!' + '</p></div>', icon: 'error',
        });

        this.inoviceService.delete_cloudinary({
          'token': data.data.delete_token
        }).then((delet) => {
          console.log(delet);
        })

      }


      function getNumbersInString(string) {
        var tmp = string.split("");
        var map = tmp.map(function (current) {
          if (!isNaN(parseInt(current))) {
            return current;
          }
        });

        var numbers = map.filter(function (value) {
          return value != undefined;
        });

        return numbers.join("");
      }

    } catch (error) {
      Swal.fire({
        title: "NO se realizo el proceso, comunicate con el administrador",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }
  }

  async saveAll() {
    try {

      console.log(this.arrInserDocu)
      this.Cargando = 'Block';

      if (this.arrInserDocu.length == 0) {
        this.Cargando = 'none';
        return (Swal.fire({ title: "NO se puede guardar la informacion sin validar los archivos por favor verificar", icon: 'error' }))
      }

      await this.invoiceService.insert_received_documents(this.arrInserDocu).then((res) => {
        if (res) {
          Swal.fire({ title: "Proceso completado correctamente", icon: 'success', timer: 5000 });

          this.arrInserDocu = [];
          this.cargar_recevied_documents();
          this.formDocu.reset();
          this.btnguardar = true;
        }
      }, error => {
        Swal.fire({
          title: "No se realizó el proceso, comunicate con el administrador",
          html: ' <div class="well well-sm ContenedorGris"><label>Respuesta </label><br /><p>' + error + '</p></div>',
          icon: 'error'
        });

        this.arrInserDocu = [];
        this.cargar_recevied_documents();
        this.formDocu.reset();
        this.btnguardar = true;

      })

      this.Cargando = 'none';
    } catch (error) {

      Swal.fire({
        title: "No se realizó el proceso, comunicate con el administrador",
        html: ' <div class="well well-sm ContenedorGris"><label>Respuesta </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });

      this.arrInserDocu = [];
      this.cargar_recevied_documents();
      this.formDocu.reset();
      this.btnguardar = true;
    }

  }

  async acuse_recibido(data: any) {
    try {

      this.Cargando = 'Block';

      this.leer_acrhivo_xml_envio(data.docbase64).then(async (doc) => {
        await this.apidianService.sendevent(
          {
            "event_id": '1',
            "base64_attacheddocument_name": data.chrnamexml,
            "base64_attacheddocument": doc

          }).then(async (res) => {

            if (res.success) {
              if (res.ResponseDian.Envelope.Body.SendEventUpdateStatusResponse.SendEventUpdateStatusResult.IsValid == 'true') {
                await this.inoviceService.update_received_documents({
                  idevent: 1,
                  id: data.idreceived_documents
                }).then((respuesta) => {
                  if (respuesta) {

                    this.Cargando = 'none';

                    Swal.fire({ title: "Proceso completado correctamente", icon: 'success', timer: 5000 });
                  } else {

                    Swal.fire({ title: "NO se realizo el proceso comunicate con el administrador", icon: 'error', timer: 3000 });
                  }
                })

                const index = this.arrrecive_documents.findIndex(callback => callback.idreceived_documents == data.idreceived_documents)
                this.arrrecive_documents[index].acu_recibo = 1
                this.arrrecive_documents = [...this.arrrecive_documents];
              }

            } else {

              this.Cargando = 'none';

              Swal.fire({
                title: "NO se realizo el proceso comunicate con el administrador",
                html: ' <div class="well well-sm ContenedorGris"><label>Respuesta DIAN: </label><br /><p>' + res.message + '</p></div>', icon: 'error', timer: 3000
              });

              if (res.message == "Ya se registro este evento para este documento.") {
                await this.inoviceService.update_received_documents({
                  idevent: 1,
                  id: data.idreceived_documents
                }).then((respuesta) => {
                  const index = this.arrrecive_documents.findIndex(callback => callback.idreceived_documents == data.idreceived_documents)
                  this.arrrecive_documents[index].acu_recibo = 1
                  this.arrrecive_documents = [...this.arrrecive_documents];
                })
              }


            }
          })
      }, error => {
        Swal.fire({ title: "NO se realizo el proceso comunicate con el administrador", icon: 'error', timer: 3000 });
      })

    } catch (error) {
      Swal.fire({ title: "NO se realizo el proceso comunicate con el administrador", icon: 'error', timer: 3000 });
    }

  }

  async recibo_mercancia(data: any) {
    try {

      this.Cargando = 'Block';

      this.leer_acrhivo_xml_envio(data.docbase64).then(async (doc) => {
        await this.apidianService.sendevent(
          {
            "event_id": '3',
            "base64_attacheddocument_name": data.chrnamexml,
            "base64_attacheddocument": doc

          }).then(async (res) => {

            if (res.success) {
              if (res.ResponseDian.Envelope.Body.SendEventUpdateStatusResponse.SendEventUpdateStatusResult.IsValid == 'true') {
                await this.inoviceService.update_received_documents({
                  idevent: 3,
                  id: data.idreceived_documents
                }).then((respuesta) => {
                  if (respuesta) {

                    this.Cargando = 'none';

                    Swal.fire({ title: "Proceso completado correctamente", icon: 'success', timer: 5000 });
                  } else {

                    Swal.fire({ title: "NO se realizo el proceso comunicate con el administrador", icon: 'error', timer: 3000 });
                  }
                })

                const index = this.arrrecive_documents.findIndex(callback => callback.idreceived_documents == data.idreceived_documents)
                this.arrrecive_documents[index].rec_bienes = 1
                this.arrrecive_documents = [...this.arrrecive_documents];
              }

            } else {

              this.Cargando = 'none';

              Swal.fire({
                title: "NO se realizo el proceso comunicate con el administrador",
                html: ' <div class="well well-sm ContenedorGris"><label>Respuesta DIAN: </label><br /><p>' + res.message + '</p></div>', icon: 'error', timer: 3000
              });

              if (res.message = "Ya se registro este evento para este documento.") {
                await this.inoviceService.update_received_documents({
                  idevent: 3,
                  id: data.idreceived_documents
                }).then((respuesta) => {
                  const index = this.arrrecive_documents.findIndex(callback => callback.idreceived_documents == data.idreceived_documents)
                  this.arrrecive_documents[index].rec_bienes = 1
                  this.arrrecive_documents = [...this.arrrecive_documents];
                })
              }

            }
          })
      }, error => {
        Swal.fire({ title: "NO se realizo el proceso comunicate con el administrador", icon: 'error', timer: 3000 });
      })

    } catch (error) {
      Swal.fire({ title: "NO se realizo el proceso comunicate con el administrador", icon: 'error', timer: 3000 });
    }
  }

  async aceptacion(data: any) {
    try {

      this.Cargando = 'Block';

      this.leer_acrhivo_xml_envio(data.docbase64).then(async (doc) => {
        await this.apidianService.sendevent(
          {
            "event_id": '4',
            "base64_attacheddocument_name": data.chrnamexml,
            "base64_attacheddocument": doc

          }).then(async (res) => {

            if (res.success) {
              if (res.ResponseDian.Envelope.Body.SendEventUpdateStatusResponse.SendEventUpdateStatusResult.IsValid == 'true') {
                await this.inoviceService.update_received_documents({
                  idevent: 4,
                  id: data.idreceived_documents
                }).then((respuesta) => {
                  if (respuesta) {

                    this.Cargando = 'none';

                    Swal.fire({ title: "Proceso completado correctamente", icon: 'success', timer: 5000 });
                  } else {

                    Swal.fire({ title: "NO se realizo el proceso comunicate con el administrador", icon: 'error', timer: 3000 });
                  }
                })

                const index = this.arrrecive_documents.findIndex(callback => callback.idreceived_documents == data.idreceived_documents)
                this.arrrecive_documents[index].aceptacion = 1
                this.arrrecive_documents = [...this.arrrecive_documents];
              }

            } else {

              this.Cargando = 'none';

              Swal.fire({
                title: "NO se realizo el proceso comunicate con el administrador",
                html: ' <div class="well well-sm ContenedorGris"><label>Respuesta DIAN: </label><br /><p>' + res.message + '</p></div>', icon: 'error', timer: 3000
              });


              if (res.message = "Ya se registro este evento para este documento.") {
                await this.inoviceService.update_received_documents({
                  idevent: 4,
                  id: data.idreceived_documents
                }).then((respuesta) => {
                  const index = this.arrrecive_documents.findIndex(callback => callback.idreceived_documents == data.idreceived_documents)
                  this.arrrecive_documents[index].aceptacion = 1
                  this.arrrecive_documents = [...this.arrrecive_documents];
                })
              }

            }
          })
      }, error => {
        Swal.fire({ title: "NO se realizo el proceso comunicate con el administrador", icon: 'error', timer: 3000 });
      })

    } catch (error) {
      Swal.fire({ title: "NO se realizo el proceso comunicate con el administrador", icon: 'error', timer: 3000 });
    }

  }

  async rechazo(data: any) {
    try {

      this.Cargando = 'Block';

      this.leer_acrhivo_xml_envio(data.docbase64).then(async (doc) => {
        await this.apidianService.sendevent(
          {
            "event_id": '2',
            "base64_attacheddocument_name": data.chrnamexml,
            "base64_attacheddocument": doc

          }).then(async (res) => {

            if (res.success) {
              if (res.ResponseDian.Envelope.Body.SendEventUpdateStatusResponse.SendEventUpdateStatusResult.IsValid == 'true') {
                await this.inoviceService.update_received_documents({
                  idevent: 2,
                  id: data.idreceived_documents
                }).then((respuesta) => {
                  if (respuesta) {

                    this.Cargando = 'none';

                    Swal.fire({ title: "Proceso completado correctamente", icon: 'success', timer: 5000 });
                  } else {

                    Swal.fire({ title: "NO se realizo el proceso comunicate con el administrador", icon: 'error', timer: 3000 });
                  }
                })

                const index = this.arrrecive_documents.findIndex(callback => callback.idreceived_documents == data.idreceived_documents)
                this.arrrecive_documents[index].rechazo = 1
                this.arrrecive_documents = [...this.arrrecive_documents];
              }

            } else {

              this.Cargando = 'none';

              Swal.fire({
                title: "NO se realizo el proceso comunicate con el administrador",
                html: ' <div class="well well-sm ContenedorGris"><label>Respuesta DIAN: </label><br /><p>' + res.message + '</p></div>', icon: 'error', timer: 3000
              });

              await this.inoviceService.update_received_documents({
                idevent: 2,
                id: data.idreceived_documents
              }).then((respuesta) => {
                const index = this.arrrecive_documents.findIndex(callback => callback.idreceived_documents == data.idreceived_documents)
                this.arrrecive_documents[index].acu_recibo = 2
                this.arrrecive_documents = [...this.arrrecive_documents];
              })

            }
          })
      }, error => {
        Swal.fire({ title: "NO se realizo el proceso comunicate con el administrador", icon: 'error', timer: 3000 });
      })

    } catch (error) {
      Swal.fire({ title: "NO se realizo el proceso comunicate con el administrador", icon: 'error', timer: 3000 });
    }

  }

  ver_Dian(data: any) {
    window.open("https://catalogo-vpfe.dian.gov.co/document/searchqr?documentkey=" + data.chrcufe)
  }

  getCellClass({ row, column, value }): any {
    return {
      'columnasngxdatatable': column.Nit === 'Nit'
    };
  }
}
