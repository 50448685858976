import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { ListarHistorial, ListarHistorialRoot } from 'src/app/shared/models/pedidos';
import { ListarProcesosCalidad, ListarProcesosCalidadRoot, ListarProcesosDtllCalidad, ListarProcesosDtllCalidadRoot } from 'src/app/shared/models/quality';
import { PedidosService } from '../../../shared/services/pedidos.service'
import { QualityService } from '../../../shared/services/quality.service'
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ingcalidad',
  templateUrl: './ingcalidad.component.html',
  styleUrls: ['./ingcalidad.component.scss']
})
export class IngcalidadComponent implements OnInit {

  Cargando: string
  IdEmpresa: string;
  IdSucursal: string;
  IdUsuario: string;
  IdProCalidad: number;
  IdProDtllCalidad: number;
  boton: any;

  keywordProceso: string;
  keywordDtllProceso: string;
  isloading: boolean;

  FromTrabajos: FormGroup;
  datos_get: any;

  arrHistorial: ListarHistorial[];
  arrProcesoCalidad: ListarProcesosCalidad[];
  arrDtllProceso: ListarProcesosDtllCalidad[]
  temp: ListarHistorial[];

  constructor(public bsModalRef: BsModalRef, public options: ModalOptions, private fb: FormBuilder, public pedidoService: PedidosService, public _QualityService: QualityService) {

    this.Cargando = "none"
    this.datos_get = this.options.initialState;
    this.IdEmpresa = JSON.parse(sessionStorage.getItem('IdEmpresa'));
    this.IdSucursal = JSON.parse(sessionStorage.getItem('IdSucursal'));
    this.IdUsuario = JSON.parse(sessionStorage.getItem('idtblTercero'));



    this.keywordProceso = "ChrNombreProc"
    this.keywordDtllProceso = "ChrNombreDtllCalidad"
    this.isloading = false

    console.log(this.datos_get)

    this.FromTrabajos = new FormGroup({
      procesos: this.fb.array([])
    })

    this.buildForm();
    this.CargarProcesosCalidad();
    this.CargarHistorial();


  }

  ngOnInit(): void {
  }

  async buildForm() {
    const tiempoTranscurrido = Date.now();
    const hoy = new Date(tiempoTranscurrido);
    const controlArray = this.FromTrabajos.get("procesos") as FormArray;
    Object.keys(this.datos_get).forEach(async (i) => {
      controlArray.push(
        this.fb.group({
          Caja: new FormControl(this.datos_get[i].Caja),
          Odontologo: new FormControl(this.datos_get[i].Odontologo),
          Paciente: new FormControl(this.datos_get[i].Paciente),
          Trabajo: new FormControl(this.datos_get[i].Trabajo),
          Proceso: new FormControl(this.datos_get[i].Proceso),
          IdHistorial: new FormControl(this.datos_get[i].idtblDtllHistorico),
          IdProcesoCalidad: new FormControl(),
          IdDtllCalidad: new FormControl(),
          IdUsuario: new FormControl(this.IdUsuario),
          Observacion: new FormControl(),
        })
      )
    })
  }

  async CargarHistorial() {
    try {
      this.Cargando = 'Block';

      const res: ListarHistorialRoot = await this.pedidoService.getAllHistorial(
        {
          IdDtllPedido: this.datos_get[0].idtblDtllPedidos
        })

      this.temp = [...res.Listar_Historial]
      this.arrHistorial = res.Listar_Historial;

      this.Cargando = 'none';

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }

  }

  async CargarProcesosCalidad() {
    try {
      this.Cargando = 'Block';

      const res: ListarProcesosCalidadRoot = await this._QualityService.ListarProCalidad(
        {
          IdEmpresa: this.IdEmpresa,
          IdSucursal: this.IdSucursal
        })

      this.arrProcesoCalidad = res.Listar_Procesos_Calidad;

      this.Cargando = 'none';

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }

  }

  async selectEventProceos(item: ListarProcesosCalidad) {
    const controlArray = this.FromTrabajos.get("procesos") as FormArray;
    controlArray.controls[0].get("IdProcesoCalidad").setValue(item.idtblCalidadPro)
    this.IdProCalidad = item.idtblCalidadPro
    await this.CargarDtllProcesosCalidad(item.idtblCalidadPro)
  }

  selectEventDtll(item: ListarProcesosDtllCalidad) {
    const controlArray = this.FromTrabajos.get("procesos") as FormArray;
    controlArray.controls[0].get("IdDtllCalidad").setValue(item.idtblDtllCalidad)
  }

  onChangeSearch() {
  }

  onFocused() {
  }


  async CargarDtllProcesosCalidad(idProcesos: number) {
    try {
      this.Cargando = 'Block';

      const res: ListarProcesosDtllCalidadRoot = await this._QualityService.ListarDtllCalidad(
        {
          IdProCalidad: idProcesos
        })

      this.arrDtllProceso = res.Listar_Procesos_DtllCalidad;

      this.Cargando = 'none';

    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }

  }

  async updateAprobado(row: ListarHistorial) {
    try {

      let ocupado;

      if (row.BlnAprobado == true) {
        ocupado = false;
      } else {
        ocupado = true;
      }

      this.Cargando = 'Block';

      await this.pedidoService.UpdateNoAproHist(
        {
          idtblDtllHistorico: row.idtblDtllHistorico,
          Ocupada: ocupado
        }).then((resp) => {
          if (resp) {
            Swal.fire({ title: "Proceso completado correctamente", icon: 'success', timer: 5000 });
            this.Cargando = 'none';
          } else {
            Swal.fire({
              title: "NO se realizo el proceso...!!",
              html: ' <div class="well well-sm ContenedorGris"><label>Error </label><br /><p>' + resp + '</p></div>', icon: 'error'
            });
          }
        })

    } catch (error) {
      Swal.fire({
        title: "NO se realizo el proceso...!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error </label><br /><p>' + error + '</p></div>', icon: 'error'
      });
    }


  }

  Cancelaringreso() {
    this.bsModalRef.hide()
  }

  async guardarHistCalid() {
    try {
      this.Cargando = 'Block';

      await this._QualityService.InsertarDtllCalHistorial(this.FromTrabajos.value).then(res =>{
        this.Cargando = 'none';
        Swal.fire({
          title: "Proceso completado correctamente",
          icon: 'success'
        });

        this.bsModalRef.hide()


      }).catch(error => {
        this.Cargando = 'none';
        Swal.fire({
          title: "No se realizo el proceso...!!!",
          html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
          icon: 'error'
        });
      })
    
      
    } catch (error) {
      this.Cargando = 'none';
      Swal.fire({
        title: "No se realizo el proceso...!!!",
        html: ' <div class="well well-sm ContenedorGris"><label>Error : </label><br /><p>' + error + '</p></div>',
        icon: 'error'
      });
    }
  }

}
