<form [formGroup]="FromTrabajos">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12 col-xl-12">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-header">
                            <h5 style="text-align: center;"><b class="titulo">Ingreso de Inventario</b></h5>
                            <button type="button" class="btn-close close pull-right" aria-label="Close"
                                (click)="bsModalRef.hide()">
                                <span aria-hidden="true" class="visually-hidden">&times;</span>
                            </button>
                        </div>

                        <div [formArrayName]="'procesos'">
                            <div class="card-body" style="padding: 10px 30px 10px 30px;"
                                *ngFor="let item of FromTrabajos.get('procesos')['controls'];  let i = index;"
                                [formGroupName]="i">
                                <hr width=auto>
                                <div class="row" style="justify-content: center;">
                                    <label class="col-form-label pt-0"> <b>Caja:</b>
                                        {{FromTrabajos.get('procesos')['controls'][i].value.Caja}}</label>
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    <label class="col-form-label pt-0"> <b>Odontologo:</b>
                                        {{FromTrabajos.get('procesos')['controls'][i].value.Odontologo}}</label>
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    <label class="col-form-label pt-0"> <b>Paciente:</b>
                                        {{FromTrabajos.get('procesos')['controls'][i].value.Paciente}}</label>
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    <label class="col-form-label pt-0"> <b>Proceso:</b>
                                        {{FromTrabajos.get('procesos')['controls'][i].value.Proceso}}</label>
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    <label class="col-form-label pt-0"> <b>Trabajo:</b>
                                        {{FromTrabajos.get('procesos')['controls'][i].value.Trabajo}}</label>
                                </div>

                                <div class="row" style="justify-content: center; font: 12px;">
                                    <div class="card-body custom-datatable">
                                        <ngx-datatable class="bootstrap" [rows]="arrHistorial" [columnMode]="'standard'"
                                            [headerHeight]="50" [footerHeight]="'auto'" [rowHeight]="'auto'" [limit]="10" [scrollbarH]="true">
            
                                            <ngx-datatable-column [width]="150" name="Fecha" prop="DtmFecha">
                                                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                                    {{row.DtmFecha | date: 'medium'}}
                                                </ng-template>
                                            </ngx-datatable-column>
            
                                            <ngx-datatable-column [width]="130" name="Proceso" prop="ChrNombreProceso">
                                            </ngx-datatable-column>
                                            <ngx-datatable-column [width]="500" name="Descripcion" prop="ChrDetllhisto">
                                            </ngx-datatable-column>
                                            <ngx-datatable-column [width]="100" name="Responsable" prop="ChrNombreCompleto">
                                            </ngx-datatable-column>
                                            <ngx-datatable-column [width]="100" name="¿Aprobado?">
                                                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                                    <label class="toggle">
                                                      <input class="toggle-checkbox" (click)="updateAprobado(row)" type="checkbox"
                                                        [checked]="row.BlnAprobado">
                                                      <div class="toggle-switch"></div>
                                                      <span class="toggle-label"></span>
                                                    </label>
                                                  </ng-template>
                                            </ngx-datatable-column>
                                        </ngx-datatable>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6 col-sm-2">
                                        <label class="col-form-label pt-0">*Proceso:</label>
                                        <div class="ng-autocomplete">
                                            <ng-autocomplete [data]="arrProcesoCalidad" [searchKeyword]="keywordProceso"
                                                placeholder="Seleciona Proceso" (selected)='selectEventProceos($event)'
                                                (inputChanged)='onChangeSearch($event)' (inputFocused)='onFocused($event)'
                                                [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate"
                                                [isLoading]='isloading'>
                                            </ng-autocomplete>
    
                                            <ng-template #itemTemplate let-item>
                                                <a [innerHTML]="item.ChrNombreProc"></a>
                                            </ng-template>
    
                                            <ng-template #notFoundTemplate let-notFound>
                                                <div [innerHTML]="NoResultado"></div>
                                            </ng-template>
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-sm-2">
                                        <label class="col-form-label pt-0">*Puntos Criticos:</label>
                                        <div class="ng-autocomplete">
                                            <ng-autocomplete [data]="arrDtllProceso" [searchKeyword]="keywordDtllProceso"
                                                placeholder="Seleciona Punto Critico" (selected)='selectEventDtll($event)'
                                                (inputChanged)='onChangeSearch($event)' (inputFocused)='onFocused($event)'
                                                [itemTemplate]="itemTemplateDtll" [notFoundTemplate]="notFoundTemplateDtll"
                                                [isLoading]='isloading'>
                                            </ng-autocomplete>
    
                                            <ng-template #itemTemplateDtll let-item>
                                                <a [innerHTML]="item.ChrNombreDtllCalidad"></a>
                                            </ng-template>
    
                                            <ng-template #notFoundTemplateDtll let-notFound>
                                                <div [innerHTML]="NoResultado"></div>
                                            </ng-template>
                                        </div>
                                    </div>

                                    <div class="col-md-12 col-sm-3">
                                        <label class="col-form-label pt-0">*Observacion</label>
                                        <textarea class="form-control" id="validationCustom03" rows="2"
                                            placeholder="Observaciones del director tecnico"
                                            formControlName="Observacion"></textarea>
                                    </div>
                                   
                                </div>
                            </div>

                            <div class="row" style="justify-content: end;">
                                <div class="btn-group">
                                    <button *ngIf="!boton" class="btn btn-primary"
                                        type="submit" (click)="guardarHistCalid()">Guardar</button>
                                    <button *ngIf="boton" class="btn btn-primary" disabled
                                        type="submit">Guardando...!!!</button>
                                    &nbsp;

                                    <button class="btn btn-warning" type="button"
                                        (click)="Cancelaringreso()">Cancelar</button>
                                </div>

                            </div>

                        </div>

                        <div class="spinnerBackdrop" [ngStyle]="{'display': Cargando }">
                            <div class="spinnerContent">
                                <div class="spinnerItem">
                                    <div class="line bg-primary"></div>
                                    <div class="line bg-primary"></div>
                                    <div class="line bg-primary"></div>
                                    <div class="line bg-primary"></div>
                                </div>
                                <div class="MensajeDeEspera">Cargando...!!!</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>