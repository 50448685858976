<div class="col-sm-12 pt-3">
    <div class="container-fluid">
        <div class="row">

            <div class="col-sm-12 col-xl-4">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-header">
                            <h5><b class="titulo">Busqueda</b></h5>
                        </div>

                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-6 col-sm-6">
                                    <input class="form-control" placeholder="Caja" (keyup)='updateFilterCaja($event)'
                                        [(ngModel)]="ngmcaja" [ngModelOptions]="{standalone: true}">
                                </div>
                                <div class="col-md-6 col-sm-6">
                                    <input class="form-control" placeholder="Doctor"
                                        (keyup)='updateFilterOdontologo($event)' [(ngModel)]="ngmDoctor"
                                        [ngModelOptions]="{standalone: true}">
                                </div>

                            </div>
                            <div class="row">
                               
                                <div class="col-md-6 col-sm-6">
                                    <input class="form-control" placeholder="Paciente"
                                        (keyup)='updateFilterPaciente($event)' [(ngModel)]="ngmPaciente"
                                        [ngModelOptions]="{standalone: true}">
                                </div>
                                <div class="col-md-6 col-sm-6">
                                    <input class="form-control" placeholder="Trabajo"
                                        (keyup)='updateFilterTrabajo($event)' [(ngModel)]="ngmTrabajo"
                                        [ngModelOptions]="{standalone: true}">
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-xl-4">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-header">
                            <h5><b class="titulo">Acciones</b></h5>
                        </div>

                        <div class="card-body">
                            <div class="row">
                                <div class="card-body btn-showcase">
                                    <button (click)="showModalCambio(arrSelected)" class="btn btn-success btn-sm"
                                        type="button" placement="top" ngbTooltip="Cambiar Proceso"><i
                                            class="fa fa-paper-plane-o"></i> Cambio de Proceso</button>
                                    
                                            <button (click)="UdptaRealizado(arrSelected)" class="btn btn-warning btn-sm"
                                        type="button" placement="top" ngbTooltip="Marcar Realizado"><i
                                            class="fa fa-check"></i> Marcar Realizado </button>

                                    <button (click)="cambioFecha(arrSelected)" class="btn btn-primary btn-sm"
                                        type="button" placement="top" ngbTooltip="Cambiar Fecha"><i
                                            class="fa fa-calendar"></i> Cambio de Fecha</button>

                                    <button (click)="showModalInventario(arrSelected)" class="btn btn-danger btn-sm"
                                        type="button" placement="top" ngbTooltip="Inventario"><i
                                            class="fa fa-cubes"></i> Insertar Inventario</button>

                                    <button (click)="showModalDesinfeccion(arrSelected)" class="btn btn-secondary btn-sm"
                                        type="button" placement="top" ngbTooltip="Desinfección"><i
                                            class="fa fa-sign-language"></i> Proceso Desinfección</button>

                                            

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-xl-4">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-header">
                            <h5><b class="titulo">Calidad</b></h5>
                        </div>

                        <div class="card-body">
                            <div class="row">
                                <div class="card-body btn-showcase">
                                    <button (click)="showModalFirmaConfomidad(arrSelected)" class="btn btn-success btn-sm"
                                        type="button" placement="top" ngbTooltip="Conformidad"><i
                                            class="fa fa-check"></i> Firma Conformidad</button>
                                    
                                            <button (click)="showModalIngresoCalidad(arrSelected)" class="btn btn-warning btn-sm"
                                        type="button" placement="top" ngbTooltip="Insertar Observacion"><i
                                            class="fa-file-text-o"></i> Ingreso Observacion </button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>

        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-body custom-datatable">
                        <ngx-datatable class="bootstrap" [rows]="arrAgenda" [columnMode]="'force'"
                            [headerHeight]="'auto'" [footerHeight]="'auto'" [rowHeight]="'auto'" [limit]="20"
                            [scrollbarH]="true" [selected]="arrSelected" [selectionType]="'checkbox'"
                            [selectAllRowsOnPage]="false" (select)='onSelectTable($event)'>

                            <ngx-datatable-column [width]="30" [sortable]="false" [canAutoResize]="false"
                                [draggable]="false" [resizeable]="false" [headerCheckboxable]="true"
                                [checkboxable]="true" [cellClass]="getCellClass">
                            </ngx-datatable-column>

                            <ngx-datatable-column [width]="80" name="Caja" prop="Caja"></ngx-datatable-column>
                            <ngx-datatable-column [width]="150" name="Odontologo" prop="Odontologo">
                            </ngx-datatable-column>
                            <ngx-datatable-column [width]="150" name="Pacientes" prop="Paciente"></ngx-datatable-column>
                            <ngx-datatable-column [width]="150" name="Trabajo" prop="Trabajo">
                            </ngx-datatable-column>
                            <ngx-datatable-column [width]="150" name="Proceso" prop="Proceso"></ngx-datatable-column>
                            <ngx-datatable-column [width]="80" name="Cant" prop="Cantidad"></ngx-datatable-column>

                            <ngx-datatable-column name="Fecha Entrega" prop="Fecha">
                                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                    {{row.Fecha | date: 'mediumDate'}}
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column [width]="80" name="Hora" prop="Hora">
                            </ngx-datatable-column>

                            <ngx-datatable-column [width]="80" name="Alerta" prop="Dias">
                                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                    <div class="semaforo"
                                        [ngClass]="{ semaforo: true, redondo: true, rojo: (row.Dias <= 1), amarillo: (row.Dias > 1 && row.Dias <=3), verde: (row.Dias >3 ) }">
                                    </div>
                                </ng-template>

                            </ngx-datatable-column>

                            <ngx-datatable-column name="Acciones">
                                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>

                                    <div class="btn-group">
                                        <button (click)="showModalHistorial(row.idtblDtllPedidos)"
                                            class="btn btn-secondary btn-sm" type="button" placement="top"><i
                                                class="fa fa-history" ngbTooltip="Mostar Historial"></i></button>
                                        &nbsp;
                                        <!--   <button (click)="cambioFecha(row)" class="btn btn-primary btn-sm" type="button"
                                placement="top" ngbTooltip="Cambiar Fecha"><i class="fa fa-calendar"></i></button> -->

                                        &nbsp;
                                        <!-- <button (click)="showModalCambio(row)" class="btn btn-success btn-sm" type="button"
                                placement="top" ngbTooltip="Cambiar Proceso"><i class="fa fa-paper-plane-o"></i></button> -->
                                        &nbsp;
                                        <!-- <button (click)="UdptaRealizado(row)" class="btn btn-warning btn-sm" type="button"
                                placement="top" ngbTooltip="Marcar Realizado"><i class="fa fa-check"></i></button> -->
                                    </div>

                                </ng-template>
                            </ngx-datatable-column>
                        </ngx-datatable>
                    </div>

                    <div class="spinnerBackdrop" [ngStyle]="{'display': Cargando }">
                        <div class="spinnerContent">
                            <div class="spinnerItem">
                                <div class="line bg-primary"></div>
                                <div class="line bg-primary"></div>
                                <div class="line bg-primary"></div>
                                <div class="line bg-primary"></div>
                            </div>
                            <div class="MensajeDeEspera">Cargando...!!!</div>
                        </div>
                    </div>



                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends -->